import { useTranslation } from 'next-i18next'

import React from 'react'
import { replaceWildcards } from '../../lib/helper/helper'
import Link from '../overwrites/Link'

type Props = {
  dynamicFooter: string
  location?: string
}

export function DynamicFooter({ dynamicFooter, location }: Props) {
  const { t } = useTranslation('common')
  const { categories, locations } = JSON.parse(
    dynamicFooter || '{}',
  ) as ParsedDynmicFooter

  return (
    <div className="my-8 xs:mt-16 mx-3 xs:mx-0 flex flex-col gap-12">
      {!!categories?.length && (
        <div>
          <div className="text-xl font-medium mb-5 uppercase">
            {t('dynamic-footer-' + 'categories' + (location ? '-in' : ''), {
              number: categories.length,
              location,
            })}
          </div>
          <div className="flex gap-x-3 gap-y-2 flex-wrap">
            {categories.map((c, i) => {
              const { name, slug } = c
              return (
                <Link
                  key={slug + i}
                  href={slug}
                  className="text-sm px-2 py-1 border rounded-lg hover:text-primary"
                >
                  {replaceWildcards(name)}
                </Link>
              )
            })}
          </div>
        </div>
      )}
      {!!locations?.length && (
        <div>
          <div className="text-xl font-medium mb-5 uppercase">
            {t('dynamic-footer-' + 'locations', {
              number: locations.length,
            })}
          </div>
          <div className="flex gap-x-3 gap-y-2 flex-wrap">
            {locations.map((c, i) => {
              const { name, slug } = c
              return (
                <Link
                  key={slug + i}
                  href={slug}
                  className="text-sm px-2 py-1 border rounded-lg hover:text-primary"
                >
                  {replaceWildcards(name)}
                </Link>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
