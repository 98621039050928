import React from 'react'
import { readToken } from '../lib/sanity.api'
import {
  getAllCities,
  getAllProducts,
  getClient,
  getSettings,
  getAllCountries,
  getGetAllLastViewedProducts,
} from '../lib/sanity.client'
import { GetServerSideProps } from 'next'
import type { SharedPageProps } from '../pages/_app'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import PageHead from '../components/shared/PageHead'
import { useTranslation } from 'next-i18next'
import { PageBanner } from '../components/PageBanner'
import { useLocalStorage } from '../components/LocalStorageProvider'
import { DynamicFooter } from '../components/footer/DynamicFootes'
import TileSlider from '../components/structure/TileSlider'
import { Description } from '../components/shared/Description'
import { FAQ } from '../components/shared/FAQ'

interface PageProps extends SharedPageProps {
  settings: Settings
}
export default function Page({ settings }: PageProps) {
  const { t } = useTranslation('common')
  const { lastViewedProductIds } = useLocalStorage()
  const { dynamicFooter, description1, description2, description3, faq } =
    settings
  return (
    <>
      <PageHead settings={settings} products={[]} wildcards={{}} />
      <PageBanner {...settings} coverImage={settings.banner} isLandingPage />
      <TileSlider
        labelKey={t('top-products')}
        loadWith={getAllProducts}
        type={'product'}
      />
      <TileSlider labelKey={'cities'} loadWith={getAllCities} />
      <TileSlider labelKey={'countries'} loadWith={getAllCountries} />
      {!!faq?.length && faq !== null && <FAQ faqs={faq} />}
      {description1 && description1 !== null && (
        <Description description={description1} withBorder />
      )}
      {description2 && description2 !== null && (
        <Description description={description2} withBorder />
      )}
      {description3 && description3 !== null && (
        <Description description={description3} withBorder />
      )}
      <TileSlider
        labelKey={'last-viewed-products'}
        loadWith={getGetAllLastViewedProducts(lastViewedProductIds)}
        type={'product'}
      />
      <DynamicFooter dynamicFooter={dynamicFooter} />
    </>
  )
}

interface Query {
  [key: string]: string
}

export const getServerSideProps: GetServerSideProps<PageProps, Query> = async (
  ctx,
) => {
  const { draftMode = false, locale } = ctx
  const client = getClient(draftMode ? { token: readToken } : undefined)
  const [settings, translations] = await Promise.all([
    getSettings(locale as AppLocale, client),
    serverSideTranslations(locale, ['common']),
  ])

  return {
    props: {
      ...translations,
      settings,
      draftMode,
      token: draftMode ? readToken : '',
    },
  }
}
